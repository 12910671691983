<template>
  <div class="guidance-coll">
    <a-collapse default-active-key="1" :bordered="false" @change="changeActivekey" expandIconPosition="right">
      <a-collapse-panel key="1" :show-arrow="false">
        <template #header="props">
          <div class="head-title">
            <span>1</span>
            <span>Bridge Campaign</span>
          </div>
          <img :style="{ transform: `rotate(${props.isActive ? 180 : 0}deg)` }" src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/down-icon.svg"
            alt="">
        </template>
        <div class="main-content-box">
          <p>
            <span class="txt-light">Overview :</span>
            All users can obtain the corresponding tier of the BP-FLAC Medal by using the bridge and holding assets. The
            BP-FLAC Medal will serve as a future airdrop weight. Additionally, participation in Bridge Team Campaign
            allows you to invite more people into your group, earning more valuable BP-FLAC Medals
          </p>
        </div>
      </a-collapse-panel>
      <a-collapse-panel key="2" :show-arrow="false">
        <template #header="props">
          <div class="head-title">
            <span>2</span>
            <span>Asset Calculation Method</span>
          </div>
          <img :style="{ transform: `rotate(${props.isActive ? 180 : 0}deg)` }" src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/down-icon.svg"
            alt="">
        </template>
        <div class="main-content-box">
          <p>
            To calculate the various valid assets participating in the activity, we will take a snapshot of all addresses
            on Forgenet every day from 4:00 PM to 6:00 PM (UTC+0). We will record the quantities of various assets held in
            the addresses and convert them to USD based on the current prices at that time.
          </p>
          <p>
            <span class="txt-light">• Valid assets:BTC/ETH/EON/USDT/USDC/EON LP Token</span>
          </p>
          <p>
            e.g. One address have 0.3ETH,300USDT,200USDC on the Forgenet , and the current ETH Price is $2300, so the
            valid address value will be $1190(0.3*2300+300+200).
          </p>
          <p>
            Of course, you are free to deposit, withdraw, or trade your assets at any time. However, the value of your
            valid assets will only be based on the snapshot taken at the designated time, and it will be updated daily.
            This value will also have an impact on the Bridge Campaign.
          </p>
        </div>
      </a-collapse-panel>
      <a-collapse-panel key="3" :show-arrow="false">
        <template #header="props">
          <div class="head-title">
            <span>3</span>
            <span>Individual Bridge Campaign</span>
          </div>
          <img :style="{ transform: `rotate(${props.isActive ? 180 : 0}deg)` }" src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/down-icon.svg"
            alt="">
        </template>
        <div class="main-content-box">
          <p>
            The individual bridge activity will have three tiers of BP-FLAC Medals, achieved when your valid assets were
            reach $10/$100/$500. Once your valid assets reach these three values and you have retweeted the pinned tweet,
            you can proceed to mint the corresponding Medal.
          </p>
          <p>{{$VGuidanceColl("（Youcanh_ff6d")}}</p>
          <div class="table-box" style="width: 500px;">
            <div class="table-tit">
              <span>BP-FLAC Medal</span>
              <span>Valid Value</span>
            </div>
            <div class="table-li">
              <span>Early Settlers Lv.1</span>
              <span>$10</span>
            </div>
            <div class="table-li">
              <span>Early Settlers Lv.2</span>
              <span>$100</span>
            </div>
            <div class="table-li">
              <span>Early Settlers Lv.3</span>
              <span>$500</span>
            </div>
          </div>
          <p>
            <span class="txt-light">Upgrade Star : </span>
          </p>
          <p>

            Whenever the storage period of your valid assets reaches 30 days, you will be able to upgrade your Medal. The
            upgraded Medal will feature an additional star icon, enhancing its value.
          </p>
          <p>
            e.g. When an address holds valid assets with a total value of $510 for 31 days, it can upgrade its Early
            Settlers Lv.3 to 1 star. When the duration reaches 61 days, the Early Settlers can be upgraded to 2 stars.
          </p>
          <p>
            But if you hold valid assets with a value of $510 for 28 days , and for some reason, withdraw $100 value
            asset, on the 31st day, you will be able to upgrade your Early Settlers Lv.2 to 1 star. However, the valid
            duration for your Early Settlers Lv.3 will remain at 28 days and cannot be upgraded. Of course, you can
            deposit your assets again at any time, and the valid duration for your Early Settlers Lv.3 will continue to
            increase after the snapshot on the next day.
          </p>
        </div>
      </a-collapse-panel>
      <a-collapse-panel key="4" :show-arrow="false">
        <template #header="props">
          <div class="head-title">
            <span>4</span>
            <span>Team Bridge Campaign</span>
          </div>
          <img :style="{ transform: `rotate(${props.isActive ? 180 : 0}deg)` }" src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/down-icon.svg"
            alt="">
        </template>
        <div class="main-content-box">
          <p>
            Overview: The Team Bridge Campaign will incentivize users to achieve collective goals by forming their own
            teams or joining others' teams, earning high-value BP-FLAC Medals.
          </p>
          <p>
            <span class="txt-light">Medal Information</span>
          </p>
          <div class="table-box team-table" style="width: 918px;">
            <div class="table-tit">
              <span>BP-FLAC Medal</span>
              <span>Requirement</span>
            </div>
            <div class="table-li">
              <span>Honorable Captain</span>
              <span>Team point achieve 150000</span>
            </div>
            <div class="table-li">
              <span>Honorable Team</span>
              <span>Team point achieve 150000</span>
            </div>
            <div class="table-li">
              <span>Outstanding Captain</span>
              <span>Team Rank in Top 100</span>
            </div>
            <div class="table-li">
              <span>Outstanding Team</span>
              <span>Team Rank in Top 100</span>
            </div>
            <div class="table-li">
              <span>Star-Studded Captain</span>
              <span>Invite a significant amount of members to the team</span>
            </div>
          </div>
          <p>
            <span class="txt-light">About the team :</span>
          </p>
          <p>
            &nbsp;&nbsp;•&nbsp;&nbsp;How to create a team: <br>
            After completing a basic cross-chain transaction (minimum $10 asset), you will automatically create a team and
            receive an invitation code.
          </p>
          <p>
            &nbsp;&nbsp;•&nbsp;&nbsp;How to join a team: <br>
            Use the invitation link to access the campaign page, complete the registration, and complete a basic
            cross-chain transaction (minimum 10u). You will then become a member of that team.
          </p>
          <p>
            Tips #1: An address can create a team and join another team at the same time, meaning that as long as the
            conditions are met, you can earn both the Honorable Captain Medal and Honorable Team Medal, two high-value
            BP-FLAC Medals.
          </p>
          <p>
            Tips #2: A team can have a maximum of 1 captain and 24 team members, 25 addresses in total.
          </p>
          <p>
            Tips #3: Once you join a team, you cannot exit the team.
          </p>
          <p>
            <span class="txt-light">About the team point :</span>
          </p>
          <p>
            Team points are accumulated by summing up the valid assets of all individuals within the team on a daily basis
            , and are calculated and added on the following day.
          </p>
          <p>
            e.g. On Feb.1,Team member.1 have $850, Team member.2 have $300, Team member.3 have $100. So the whole team
            point is 1250, and It will be calculated and displayed on Feb.2 <br>
            On Feb.2, all team members can see the team point is 1250, and all team members keep the asset same as
            yesterday, so all team members will see the team point is 2500 on Feb.3
          </p>
          <p>
            Of course, any increase or decrease in assets by a team member will impact the team points added on the
            following day.
          </p>
          <p>
            Additionally, whenever a new member joins the team, it will increase the team points by 1000.
          </p>
          <p>
            <span class="txt-light">About the team medal</span>
          </p>
          <p>
            When a team's team points reach 150,000, the team captain can mint the Honorable Captain Medal, and all team
            members can mint the Honorable Team Medal.
          </p>
          <p>
            <span class="txt-light">About the team rank</span>
          </p>
          <p>
            We have also introduced a team points leaderboard, featuring the top 100 teams. After the end of the bridge
            campaign, the top 100 teams will be eligible to mint the highest-value Outstanding Team Medal. (The captains
            of the top 100 teams can mint the Outstanding Captain Medal, and members of the top 100 teams can mint the
            Outstanding Team Medal)
          </p>
          <p>
            Tips: When creating a team, you will receive a default team name. Team captains can modify the name of their
            team on the campaign page. This allows others to have a clearer understanding of your team's position on the
            leaderboard.
          </p>
          <p>
            <span class="txt-light">Fighting! Captain and warriors!</span>
          </p>
          <p>
            <span class="txt-light">About the Star-Studded Captain</span>
          </p>
          <p>
            This is an individual task, all you need to do is invite a significant number of members to your team, and
            then you can mint this BP-FLAC Medal.
          </p>
        </div>
      </a-collapse-panel>
    </a-collapse>
  </div>
</template>
<script>

    /* viai18n-disable */
    import $VGuidanceCollMessages from "./GuidanceColl.messages.json";
  const $defaultObject = {
  data() {
    return {
      text: `A dog is a type of domesticated animal.Known for its loyalty and faithfulness,it can be found as a welcome guest in many households across the world.`,
      active: []
    }
  },
  methods: {
    changeActivekey(key) {
      console.log(key)
    },
  }
}


    if ($defaultObject.methods){
      Object.assign($defaultObject.methods,{
    $VGuidanceColl(key,origin){
      const messages = $VGuidanceCollMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  })
    }else{
      $defaultObject.methods = {
    $VGuidanceColl(key,origin){
      const messages = $VGuidanceCollMessages;
      if(!this.$lang && !messages["zh"]) return origin||key;
      const trans = messages[this.$lang]||messages["zh"]||{};
      return trans[key]===undefined?(origin||key):trans[key];
    },
  }
    }
    export default $defaultObject
  </script>
<style lang="scss" scoped>
.guidance-coll {}
</style>
<style lang="scss">
.ant-collapse {
  color: rgba(255, 255, 255, 0.60);

  .ant-collapse-item {
    border-color: rgba(255, 255, 255, 0.20);
    margin-bottom: 40px;

    .ant-collapse-header {
      color: rgba(255, 255, 255, 0.60);
      font-size: 24px;
      font-weight: 700;
      height: 106px;
      padding: 0;
      padding-left: 0 !important;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @screen md {
        height: 60px;
        color: #fff;
        font-size: 14px;
        padding-bottom: 24px !important;
      }

      .head-title {
        display: flex;
        align-items: center;
        gap: 24px;

        & span:first-of-type {
          display: inline-block;
          width: 78px;
          height: 66px;
          text-align: center;
          line-height: 66px;
          background: url(https://wami-wod.oss-cn-beijing.aliyuncs.com/img/settingNumIcon.png) no-repeat center;
          background-size: 100% 100%;

          @screen md {
            width: 38px;
            height: 34px;
            font-size: 16px;
            line-height: 34px;
          }
        }
      }

      img {
        width: 40px;
        transition: all .2s;
        user-select: none;

        @screen md {
          width: 20px;
        }
      }
    }

    .ant-collapse-content-box {
      padding: 0;
      padding-top: 30px !important;
      padding-bottom: 60px;

      @screen md {
        padding-top: 10px !important;
        padding-bottom: 24px;
      }

      .main-content-box {
        color: #fff;
        display: flex;
        gap: 30px;
        flex-direction: column;

        @screen md {
          gap: 20px;
        }

        p {
          margin: 0;
          color: rgba(255, 255, 255, 0.60);
          font-size: 24px;
          font-weight: 410;
          line-height: 30px;

          @screen md {
            font-size: 12px;
            line-height: 20px;
          }

          .txt-light {
            background: linear-gradient(270deg, #203DFD 0%, #34C5F3 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }

        .table-box {
          display: flex;
          flex-direction: column;
          border-radius: 20px;
          border: 1px solid rgba(255, 255, 255, 0.20);

          @media (min-width: 1200px) and (max-width: 1440px) {
            /* width: 100% !important; */
          }

          @media (min-width: 667px) and (max-width: 1200px) {
            width: 100% !important;
          }

          @screen md {
            width: 100% !important;
          }

          .table-tit,
          .table-li {
            display: flex;

            span {
              padding: 35px;
              padding-right: 0;
              text-align: left;
              flex: 1;
              display: inline-block;
              border-right: 1px solid rgba(255, 255, 255, 0.20);
              border-bottom: 1px solid rgba(255, 255, 255, 0.20);
              color: rgba(255, 255, 255, 0.60);
              font-size: 24px;
              font-weight: 510;
              line-height: 30px;

              @screen md {
                padding: 16px 0 16px 21px;
                font-size: 12px;
                line-height: 20px;
              }
            }

            & span:last-of-type {
              border-right: none;
            }
          }

          & .table-li:last-of-type {
            span {
              border-bottom: none;
            }
          }
        }

        .team-table {

          .table-tit,
          .table-li {
            span {
              flex: 1;

              @screen md {
                flex: 6;
                line-height: 100%;
              }
            }

            & span:last-of-type {
              flex: 2;

              @screen md {
                flex: 8;
                line-height: 100%;
              }
            }
          }
        }
      }
    }
  }

  & .ant-collapse-item:last-of-type {
    border: none;
  }
}

.ant-collapse-borderless {
  background-color: transparent;
}</style>