<template>
  <div class="guidance">
    <Header></Header>
    <div class="guidance-content md:px-0">
      <div class="guidance-tit">
        <span>GUIDANCE</span>
      </div>
      <div class="guidance-main">
        <GuidanceColl></GuidanceColl>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import GuidanceColl from '../../components/guidance/GuidanceColl.vue';
export default {
  components: {
    Header,
    Footer,
    GuidanceColl
},
}
</script>
<style lang="scss" scoped>
.guidance {
  width: 100%;
  overflow: hidden;
  .guidance-content {
    min-height: 100vh;
    width: 1440px;
    margin: auto;
    padding: 80px 0 20px;
    @media (min-width: 1200px) and (max-width: 1440px) {
      width: 1200px;
    }
    @media (min-width: 667px) and (max-width: 1200px) {
      width: 600px;
    }
    @screen md {
      width: 342px;
      padding-top: 24px;
    }
    .guidance-tit {
      color: rgba($color: #FFF, $alpha: .6);
      font-size: 24px;
      font-weight: 590;
      line-height: 24px; /* 100% */
      margin-bottom: 46px;
      @screen md{
        margin-bottom: 26px;
        text-align: center;
      }
    }
  }
}
</style>